import { css } from '@emotion/react';
import {
  blue4,
  blue9,
  blue10,
  charcoal,
  gray4,
  gray7,
  white,
} from '@fire/_color';
import { dateInputPadding, filterBarBoxShadow, zIndex } from '@fire/_const';
import {
  bold,
  fontFace,
  fontSizeBody,
  fontSizeLarge,
  fontSizeMedium,
  lineHeightDefault,
} from '@fire/_fonts';
import { media, mediaMax } from '@fire/_mediaQueries';

export const resetButton = css`
  ${fontFace};
  background: transparent;
  border: none;
  color: ${charcoal};
  font-size: ${fontSizeBody};
  margin-right: 56px;
  padding: 0;
  text-decoration: underline;

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
  }

  actions & {
    text-align: left;
  }
`;

export const nav = css`
  width: 25px;
  position: absolute;
  top: 20px;
  width: 25px;
  height: 25px;

  &:hover {
    filter: brightness(0.5);
  }
`;

export const close = css`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: ${blue9};
  color: ${white};
  font-size: 30px;
  text-align: center;

  z-index: 1001;

  &:hover {
    background: ${blue10};
  }
`;

export const next = css`
  ${nav}
  position: absolute;
  right: 30px;
  transform: rotate(180deg);
`;

export const prev = css`
  ${nav}
  left: 28px;
`;

export const calendarInfo = css`
  background: ${gray4};
  border-top: 1px solid ${gray4};
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  left: 0;
  width: 100%;

  ${mediaMax.xs} {
    ${filterBarBoxShadow}
  }

  ${media.md} {
    background: transparent;
    border: 0;
    padding-top: 0;
    position: auto;
  }
`;

export const calendarHeader = css`
  background: ${white};
  height: 40px;
  left: 0;
  padding: 10px;
  padding-left: 25px;
  padding-top: 12px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  :empty {
    visibility: hidden;
  }

  h5 {
    font-size: ${fontSizeLarge};
    overflow: hidden;
    padding-right: 35px;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${media.md} {
      padding-right: 25px;
    }
  }

  ${media.md} {
    background: transparent;
    padding-top: 12px;
    position: absolute;
  }
`;

export const wrapper = css`
  bottom: 0;
  background: ${gray4};
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;

  ${media.md} {
    position: relative;
    flex-direction: row;
  }
`;

export const nights = css`
  align-self: center;
  text-align: center;
  font-size: 16px;
  margin-bottom: 6px;

  ${media.md} {
    width: 60%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
    margin-right: 24px;
    text-align: left;
  }
`;

export const dateInfo = css`
  width: 100%;
  height: 70px;
  background: ${blue4};
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 102px;

  img {
    width: 26px;
    height: 26px;
    margin-right: 9px;
  }

  ${media.md} {
    padding: 24px 24px;
    position: unset;
    bottom: auto;
  }
`;

export const applyButtonWrapper = css`
  width: 60%;

  ${media.md} {
    width: auto;
  }
`;

export const cancel = css`
  ${resetButton}
  cursor: pointer;
`;

export const actions = css`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;

  ${media.md} {
    width: 50%;
  }
`;

export const dateRangePicker = css`
  // react-dates style
  &.has-header .DayPicker > div > div {
    padding-top: 32px;
  }

  .DateRangePickerInput {
    border-radius: 8px;
  }

  .CalendarDay__selected_span {
    background: ${blue9};
    border-color: white;
    color: white;
  }
  // Will edit selected date or the endpoints of a range of dates
  .CalendarDay__selected {
    background: ${blue9};
    border-color: white;
    color: white;
    text-decoration: none;
  }
  // Will edit when hovered over. _span style also has this property
  .CalendarDay__selected:hover {
    background: ${blue9};
    border-color: white;
    color: white;
  }
  // Will edit when the second date (end date) in a range of dates is
  // not yet selected. Edits the dates between your mouse and said date
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${blue9};
    color: white;
    border-color: white;
  }
  .DateInput {
    width: 50%;
    border-radius: 8px;
    :first-of-type {
      border-radius: 8px 0px 0px 8px;
    }
    input {
      cursor: pointer;
      font-family: ${fontFace};
      font-size: ${fontSizeMedium};
      font-weight: ${bold};

      border-radius: 8px;
      color: ${charcoal};

      &[disabled] {
        background: ${white};
        color: ${gray7};
        cursor: not-allowed;
        font-style: normal;
      }

      &::placeholder {
        color: ${charcoal};
      }
    }
  }
  .DateInput_input {
    ${dateInputPadding}
  }
  .DayPicker_transitionContainer {
    min-height: 340px;
  }
  .CalendarDay__selected_span:hover {
    background-color: ${blue10};
    border-color: ${blue10};
  }
  .CalendarMonthGrid {
    padding-top: 10px;

    ${media.md} {
      padding-top: 0;
    }
  }
  .CalendarMonth_caption {
    padding-bottom: 7px;
    padding-top: 22px;

    ${media.md} {
      padding: 20px 0 35px;
    }
  }
  .DayPickerNavigation__vertical {
    ${media.xs} {
      position: relative;
      margin-top: 22px;
    }
  }

  .DayPickerNavigation_button {
    ${media.xs} {
      width: 100vw;
    }
  }

  .CalendarDay__default {
    font-weight: bold;
    padding: 9px;
  }
  .DateRangePicker_picker {
    z-index: 11;
  }
  .DateInput_fang {
    z-index: 12;
  }
  .DateInput_input__focused {
    border-bottom: 2px solid transparent;
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_calendar {
    background: transparent;
    border-color: #e4e7e7;
    color: #cacccd;
    text-decoration: line-through;
  }
  .DateRangePicker_closeButton {
    position: fixed;
    z-index: ${zIndex.layerTop};
  }

  .DayPicker_weekHeader {
    background: #fff;
    border-bottom: 1px solid #dbdbdb;
    display: table-row;
    left: 0;
    line-height: ${lineHeightDefault};
    margin-left: 0 !important; // I know, but it must be here
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: ${zIndex.layerFour};

    ${media.md} {
      background: transparent;
      border: 0;
      position: absolute;
      text-align: left;
      top: 60px;
    }
  }
`;

export const nextMobile = css`
  svg {
    transform: rotate(180deg);
  }
`;
export const prevMobile = css`
  z-index: 1;
`;

export const tabs = css`
  display: flex;
  height: 45px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% - 40px);
  z-index: 2;

  ${media.md} {
    width: 100%;
  }

  button {
    background: #f1f1f1;
    border: 0;
    color: #1f74ac;
    cursor: pointer;
    font-size: ${fontSizeLarge};
    font-weight: 700;
    padding: 8px;
    text-align: center;
    text-decoration: underline;
    transition: all 0.5s ease;
    width: 100%;

    &[aria-selected='true'] {
      background: #fff;
      color: ${charcoal};
      cursor: default;
      pointer-events: none;
      text-decoration: none;
    }
  }
`;

export const selectedTab = css`
  background: #fff !important;
  color: ${charcoal} !important;
  cursor: default !important;
  pointer-events: none;
  text-decoration: none !important;
`;
